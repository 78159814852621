






































































































































































































































































































































import { Ref, ref, computed, defineComponent } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
  ValidationLogin,
  ValidateCheked,
  LogInfo,
  HttpResponseInterface
} from '@/models';
import { isEmptyObject, isIOS, validateCheked } from '@/services/common/utils';
import { encryptRsa } from '@/services';
import Store from '@/store';
import router from '@/router';
import RSA from '@/services/rsa.js';
import ConfirmDialog from '@/dialogs/ConfirmDialog.vue';
import ExpireCoinListDialog from '@/dialogs/ExpireCoinListDialog.vue';
import InputDeleteButton from '@/components/atoms/buttons/InputDeleteButton.vue';
import Login from '@/views/login/Login.vue';
import { MenuHelperService } from '@/services/menu/menu.helpers';
import { i18nWrapRuby, t } from '@/commons/i18n/index';
import { sendBip, ApiException } from '@/services/index';
import { LANGUAGE_TYPES } from '@/commons/constants/config-language';
import { dateConvert } from '@/services/common/utils';
import { Coin } from '@/models/user-info';

export default defineComponent({
  name: 'SideBar',
  components: {
    ConfirmDialog,
    Login,
    InputDeleteButton,
    ValidationProvider,
    ValidationObserver
  },
  setup(_props, { root }) {
    const { dispatch, getters, commit } = Store;
    const isAuth = computed(() => getters['AuthStore/isAuth']);
    const userInfo = computed(() => getters['MyPageStore/userInfo']);
    const isJp = computed(
      () => getters['I18nStore/getCookieLanguage'] === LANGUAGE_TYPES.JA_JP
    );
    const getAcceptMature = ref(
      sessionStorage.getItem('acceptMature') === 'Y' ? true : false
    );
    const errorMsg: Ref<ValidationLogin> = ref({ email: '', password: '' });
    const initFormData = { email: '', password: '' };
    const formData = ref({ ...initFormData });
    const refs = ref({
      rsaKeys: computed(() => getters['AuthStore/getRsaKeys']),
      getAuthErrInfo: computed(() => getters['AuthStore/getAuthErrInfo']),
      userInfo: computed(() => getters['MyPageStore/userInfo'])
    });
    const currentRouter = router.currentRoute.path;
    const showTooltip = ref(false);
    const EXPIRE_LIST_DAY = 0;
    const EXPIRES_MAX_DAY = 30;

    const getExternalIdpUrl = (loginType: string) =>
      dispatch('SocialLoginStore/getExternalIdpUrl', loginType);

    const closeMenu = () => commit('GlobalStore/setUserMenuState', false);

    /** 유효성 검증 */
    const validateFrom = async () => {
      const opt: ValidateCheked = {
        validate,
        entriesArray: formData.value,
        schema: {
          email: 'requiredEmail|invalidEmailAddress',
          password: 'requiredPassword|invalidWitespace|invalidPassword:true'
        }
      };
      const resArray = await validateCheked(opt);
      errorMsg.value = resArray as ValidationLogin;
      return isEmptyObject(resArray) ? false : true;
    };

    /** RSA 암호화 */
    const encryptoRSA = () => {
      try {
        const rsa = new RSA.RSAKey();
        rsa.setPublic(
          refs.value.rsaKeys.publicKeyModulus,
          refs.value.rsaKeys.publicKeyExponent
        );
        const values = {
          key: refs.value.rsaKeys.sessionKey,
          char1: formData.value.email,
          char2: formData.value.password
        };
        return rsa.encrypt(encryptRsa(rsa.getLenChar, values));
      } catch (err) {
        console.error(`LoginEmail encryptoRSA err ${err}`);
        return null;
      }
    };

    const onLogin = async () => {
      const enc = encryptoRSA();
      if (!enc) return;
      const auth = {
        email: formData.value.email,
        sessionKey: refs.value.rsaKeys.sessionKey,
        rsaEncryptedString: enc
      };
      return await dispatch('AuthStore/LOGIN', auth);
    };

    /** 로그인 */
    const handleSubmit = async () => {
      const isValidateErr = await validateFrom();
      if (isValidateErr) return;
      await dispatch('AuthStore/GET_RSA_KEYS', 'LOGIN');
      const { data } = await onLogin();

      if (!isEmptyObject(data)) {
        if (refs.value.userInfo) {
          Object.assign(formData.value, initFormData);
          const redirectQuerys = root.$route.query['redirect'] || undefined;
          router.replace({
            name: 'Authorize',
            params: { nextPage: 'true' },
            query: {
              redirect: redirectQuerys ? redirectQuerys : root.$route.path
            }
          });
        }
      }
    };

    const pathCheck = (path: string) => {
      const userMenu: {
        name: string;
        param: string;
      } | null = MenuHelperService.getUserMenu(path);
      switch (true) {
        case path === 'library':
          return userMenu
            ? `/library/${userMenu.param}`
            : '/library/subscribed';
        default:
          return path;
      }
    };

    const goToLink = (path: string) => {
      if (root.$route.path !== pathCheck(path)) {
        router.push(pathCheck(path));
      } else {
        closeMenu();
      }
    };

    const externalIdpConnect = (loginType: string) => {
      sessionStorage.setItem('redirectUrl', currentRouter);
      getExternalIdpUrl(loginType).then((res) => {
        document.location.href = res;
      });
    };

    const acceptMature = () => {
      if (isAuth.value) {
        sessionStorage.setItem(
          'acceptMature',
          sessionStorage.getItem('acceptMature') === 'Y' ? 'N' : 'Y'
        );
        dispatch(
          'MyPageStore/putMature',
          userInfo.value.mature.acceptMature ? 'off' : 'on'
        );
      } else {
        sessionStorage.setItem(
          'acceptMature',
          sessionStorage.getItem('acceptMature') === 'Y' ? 'N' : 'Y'
        );
        commit('MyPageStore/setAcceptMature');
      }
    };

    const getExpiredDays = (coin: Coin) => {
      try {
        const { time, type } = dateConvert(
          coin.closestExpireAt.toString(),
          'expired'
        );
        switch (type) {
          case 'min':
          case 'hour':
            return 1;
          case 'date':
            return 0;
          default:
            return time;
        }
      } catch {
        return 0;
      }
    };

    const openPopup = () => {
      dispatch('MyPageStore/getCoinExpireDay', EXPIRE_LIST_DAY)
        .then(() => {
          root.$q.dialog({
            component: ExpireCoinListDialog
          });
        })
        .catch((err: HttpResponseInterface) => {
          ApiException(err.result.code, {
            message: err.result.message,
            response: { url: router.currentRoute.path }
          });
        });
    };

    const replaseTermsLink = (text: string): string => {
      let regTermsOfUse = new RegExp(t('terms_of_use'), 'g');
      let regPrivacyPolicy = new RegExp(t('privacy_policy'), 'g');
      text = text.replace(
        regTermsOfUse,
        "<a style='color: #adb5bd; border-bottom:1px solid;' href='/legal/terms_of_use' target='_blank'>$&</a>"
      );
      return text.replace(
        regPrivacyPolicy,
        "<a style='color: #adb5bd; border-bottom:1px solid;' href='/legal/privacy_policy' target='_blank'>$&</a>"
      );
    };

    if (isAuth.value) {
      dispatch('MyPageStore/getUserInfo');
      sendBip('/account', root.$route.path, {} as LogInfo);
    }

    if (showTooltip.value) {
      document.body.addEventListener('click', () => {
        showTooltip.value = !showTooltip.value;
      });
    }

    return {
      EXPIRES_MAX_DAY,
      t,
      i18nWrapRuby,
      isAuth,
      userInfo,
      goToLink,
      isJp,
      externalIdpConnect,
      acceptMature,
      closeMenu,
      getExpiredDays,
      formData,
      errorMsg,
      handleSubmit,
      getAcceptMature,
      showTooltip,
      openPopup,
      replaseTermsLink
    };
  },
  directives: {
    clickoutside: {
      bind: (el: any): void => {
        el.clickOutsideEvent = (event) => {
          const chkBtn = (target: EventTarget | string) =>
            event.target.parentElement.classList.value.includes(target);
          if (
            !(
              el == event.target ||
              el.contains(event.target) ||
              chkBtn('link_account') ||
              chkBtn('link_sidemenu') ||
              document.querySelector('.q-dialog')
            )
          ) {
            if (isIOS()) {
              console.log(JSON.stringify(event.target));
            }

            Store.commit('GlobalStore/setUserMenuState', false);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind: (el: any): void => {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    },
    hideTooltip: {
      bind: (el: any, _bind, vnode: any): void => {
        el.clickOutsideEvent = (event) => {
          if (event.target.classList.value !== 'btn_info') {
            vnode.context.showTooltip = false;
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind: (el: any): void => {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  }
});
