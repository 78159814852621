import { CONTENT_CATALOG } from '@/commons/constants/code-type';
import { CommonMenuItems } from '@/commons/constants/content.types';
import { MenuItem } from '@/models';
import { t } from '@/commons/i18n/index';

type MenuTypes = MenuItem | CommonMenuItems | undefined;

const USER_MENU_KEY = 'userMenu';

export function getSelectItem(items: Array<MenuItem | CommonMenuItems>, code: string): MenuTypes {
  return items
    .filter(node =>
      node.code === code
    )
    .shift();
}

export function getFirstItem(items: Array<MenuItem | CommonMenuItems>): MenuItem | CommonMenuItems {
  return { ...items[0] };
}

/**
 * param2와 일치하는 MenuItem 리턴
 * param2 없을경우
 * content_catalog 있으면 content_catalog(에디터스 픽) 리턴, 없으면 첫번째 인덱스 리턴
 * @param items
 * @param param2
 * @returns
 */
export function selectItems(items: Array<MenuItem | CommonMenuItems>, param2: string): MenuItem | CommonMenuItems {
  try {
    let selectedItem: MenuTypes;
    if (param2) {
      selectedItem = getSelectItem(items, param2);
    } else {
      selectedItem = getSelectItem(items, CONTENT_CATALOG);
    }
    return selectedItem ? selectedItem : getFirstItem(items);
  } catch (e) {
    return getFirstItem(items);
  }
}

/**
 * 오늘날짜 menu item 추출
 * @param {Array<string>} days 
 * @returns {MenuItem}
 */
const getDayToStr = () => {
  const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  if (t('lang') == 'ja') {
    const date = new Date(Date.now() + (32400 * 1000));
    const dayNum = date.getUTCDay();
    return weekdays[dayNum];
  } else if (t('lang') == 'en') {
    const date = new Date(Date.now() + (-10800 * 1000));
    const dayNum = date.getUTCDay();
    return weekdays[dayNum];
  } else if (t('lang') == 'fr') {
    const date = new Date(Date.now() + (14400 * 1000));
    const dayNum = date.getUTCDay();
    return weekdays[dayNum];
  } else if (t('lang') == 'tw') {
    const date = new Date(Date.now() + (54000 * 1000));
    const dayNum = date.getUTCDay();
    return weekdays[dayNum];
  } else {
    const dayNum = new Date().getDay();
    return weekdays[dayNum];
  };
}

export function selectDailyItems(items: Array<MenuItem | CommonMenuItems>, param2: string): MenuItem | CommonMenuItems {
  try {
    let selectedItem: MenuTypes;
    if (param2) {
      selectedItem = getSelectItem(items, param2);
    } else {
      const contentCatalog = getSelectItem(items, CONTENT_CATALOG);
      if (contentCatalog) {
        selectedItem = contentCatalog;
      } else {
        selectedItem = getSelectItem(items, getDayToStr());
      }
    }
    return selectedItem ? selectedItem : getFirstItem(items);
  } catch (e) {
    return getFirstItem(items);
  }
}

export const setUserMenu = (name: string, param: string): void => {
  const userMenu = localStorage.getItem(USER_MENU_KEY);
  const obj = Object.assign({}, userMenu ? JSON.parse(userMenu) : '', {
    [name]: {
      name,
      param
    }
  });
  localStorage.setItem(USER_MENU_KEY, JSON.stringify(obj));
};

export const getUserMenu = (cateName: string): { name: string, param: string } | null => {
  const userMenu = localStorage.getItem(USER_MENU_KEY);
  if (userMenu) {
    return (JSON.parse(userMenu)[cateName]);
  } else {
    return null;
  }
};


export const MenuHelperService = {
  getSelectItem,
  getFirstItem,
  selectItems,
  selectDailyItems,
  setUserMenu,
  getUserMenu
}