
// creator / writer / artist / assistant / original_creator / studio

export const AUTHOR_TYPE_CREATOR = 'creator';						// 작가
export const AUTHOR_TYPE_WRITER = 'writer';							// 글
export const AUTHOR_TYPE_ARTIST = 'artist';							// 그림
export const AUTHOR_TYPE_ASSISTANT = 'assistant';				// 보조
export const AUTHOR_TYPE_ORIGINAL_CREATOR = 'original_creator';	// 원작
export const AUTHOR_TYPE_STUDIO = 'studio';							// 출판사
export const AUTHOR_TYPE_LINE_ARTIST = 'line_artist';//線画
export const AUTHOR_TYPE_ADAPTER = 'adapter';//構成
export const AUTHOR_TYPE_COLORIST = 'colorist';//着色
export const AUTHOR_TYPE_OTHER = 'other';

export const AUTHOR_TYPE = {
	AUTHOR_TYPE_CREATOR,
	AUTHOR_TYPE_WRITER,
	AUTHOR_TYPE_ARTIST,
	AUTHOR_TYPE_ASSISTANT,
	AUTHOR_TYPE_ORIGINAL_CREATOR,
	AUTHOR_TYPE_STUDIO,
    AUTHOR_TYPE_LINE_ARTIST,
    AUTHOR_TYPE_ADAPTER,
    AUTHOR_TYPE_COLORIST,
	AUTHOR_TYPE_OTHER
};