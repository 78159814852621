import {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError
} from 'axios';
import jstz from 'jstz';
import { CODE_TYPE } from '@/commons/constants/code-type';
import {
  IgnorePath,
  ERROR_CODES,
  ResponseReturnCode
} from '@/commons/constants/error-codes';
import store from '@/store/index';
import { getLanguageCookie, saveCookie } from '@/helpers/cookies';
import {
  ApiException,
  SystemException,
  NetworkException,
  isWeb,
  Encrypto
} from '@/services/index';
import { getNeoChk } from '@/services/auth/auth.helper';
import { InspectorHelperService } from '@/services/common/inspector.helper';
import { LegacyApiService } from '@/services/common/exception.legacyapi';
import {
  LANGUAGE_KEY,
  LANGUAGE_EXPIRES,
  LANGUAGE_TYPES,
  languageLongMap
} from '@/commons/constants/config-language';
import { HeaderOptions } from '@/models';

/** client-immutable-uid */
export const getIpAddress = async () => {
  const ip = store.getters['AuthStore/getIP'];
  if (ip) return ip;
  return await store.dispatch('AuthStore/GET_IP_ADDRESS');
};

/** 언어 */
export const getLanguage = () => {
  const shortLan =
    getLanguageCookie() || store.getters['i18nStore/getCookieLanguage'];
  if (shortLan && shortLan.length > 0) {
    if (shortLan.length === 2) {
      saveCookie(LANGUAGE_KEY, languageLongMap[shortLan], {
        expires: LANGUAGE_EXPIRES
      });
      return languageLongMap[shortLan];
    }
    return shortLan;
  } else {
    let len = LANGUAGE_TYPES.EN_US;
    switch (process.env.VUE_APP_LANGUAGE) {
      case 'ko':
        len = LANGUAGE_TYPES.KO_KR;
        break;
      case 'ja':
        len = LANGUAGE_TYPES.JA_JP;
        break;
      default:
        len = LANGUAGE_TYPES.EN_US;
        break;
    }
    return len;
  }
};

/** 지역 */
export const timezone = () => {
  let tz: string;
  try {
    tz = jstz.determine().name();
  } catch {
    tz = 'Asia/Seoul';
  }
  return tz;
};

/** 시간 */
const generateState = () => {
  const now = new Date();
  const secondsSinceEpoch = Math.round(now.getTime() / 1000);
  return secondsSinceEpoch;
};

/** check-sum */
const checkSumFn = (timestamp: number, immutableUid: string) => {
  const tm = String(timestamp);
  const webKey = '9241d2f090d01716feac20ae08ba791a';
  const sum = webKey + immutableUid + tm;
  const enc = Encrypto.sha256(sum);
  return enc;
};

/** Delete UserInfo */
const authUpdate = ({
  neoChk,
  currentUser
}: {
  neoChk: string | undefined;
  currentUser: string | null;
}) => {
  if (neoChk && neoChk.length > 0) {
    store.commit('AuthStore/setNeoChk', neoChk);
  } else {
    if (currentUser) store.commit('AuthStore/logout');
  }
};

const errorMessage = (
  date: Date,
  result: { message: string; debug: string; code: number },
  { headers, url }
) => {
  return {
    error: result.message,
    message: result.message,
    path: '',
    status: result.code,
    timestamp: date.toDateString(),
    response: {
      headers: headers,
      url: url
    }
  };
};

const acceptMatureStatus = () =>
  sessionStorage.getItem('acceptMature')
    ? sessionStorage.getItem('acceptMature')
    : sessionStorage.setItem('acceptMature', 'Y');

/** [CKR] 소셜회원 중 휴면계정 예외처리 */
const getExceptionDormantKR = (response: AxiosResponse) => {
  const isIdpMapping = InspectorHelperService.checkResponseUrl(
    response,
    'mapping'
  );
  const isIdpSignin =
    InspectorHelperService.checkResponseUrl(response, 'external-idp') &&
    InspectorHelperService.checkResponseUrl(response, 'signin');

  if (response.data.result.code === ERROR_CODES.CODE_417) {
    if (isIdpSignin || isIdpMapping) return true;
  }

  return false;
};

const createConfig = (config: AxiosRequestConfig, options: Object) => {
  config.headers['X-comico-client-os'] = options['os'];
  config.headers['X-comico-client-store'] = options['store'];
  config.headers['X-comico-request-time'] = options['requestTime'];
  config.headers['X-comico-check-sum'] = options['checksum'];
  config.headers['X-comico-timezone-id'] = options['timezone'];
  config.headers['Accept-Language'] = options['language'];
  config.headers['X-comico-client-immutable-uid'] = options['immutableUid'];
  config.headers['X-comico-client-platform'] = options['platform'];
  config.headers['X-comico-client-accept-mature'] = options['acceptMature'];
  return config;
};

/** 요청 헤더 & 응답 데이터 인터셉터 */
export const setInterceptors = (axiosService: AxiosInstance): AxiosInstance => {
  axiosService.interceptors.request.use(
    async (config) => {
      const auth = {
        neoChk: getNeoChk(),
        currentUser: localStorage.getItem('currentUser')
      };
      authUpdate(auth);
      const immutableUid = (await getIpAddress()) || '0.0.0.0';
      const tm = generateState();
      const headerOptions: HeaderOptions = {
        os: isWeb(),
        store: CODE_TYPE.STORE.WEB,
        requestTime: tm,
        checksum: checkSumFn(tm, immutableUid),
        timezone: timezone(),
        language: getLanguage(),
        immutableUid: immutableUid,
        platform: CODE_TYPE.PLATFORM.WEB,
        acceptMature: acceptMatureStatus()
      };
      return createConfig(config, headerOptions);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  /*
    Response
    - isDormant: 한국 휴면 계정의 경우 별도 에러 처리
    - isLegacyApi: Legacy 코드로써 이전 API 에러 처리
    - isReturnYn: newCreateAxiosService로 호출할 경우 무조건 리턴, 각 서비스별 에러 처리
    - isErrorCodes: ReturnCode에 해당하지 않는 경우 에러 처리
    [권장] newCreateAxiosService 사용
  */
  axiosService.interceptors.response.use(
    (response: AxiosResponse) => {
      try {
        const { data, result } = response.data;
        const apiPath = response.config.url;
        const isDormant = getExceptionDormantKR(response);
        const isLegacyApi = apiPath && LegacyApiService.getApi(apiPath);
        const isNewAxios = InspectorHelperService.getReturnYn(apiPath) === 'Y';
        let isIgnorePath = false;
        if (apiPath) {
          for (const value of IgnorePath) {
            if (apiPath.startsWith(value)) {
              isIgnorePath = true;
              break;
            }
          }
        }
        const isErrorCodes = !ResponseReturnCode.some((c) => c === result.code);

        if (result.code === ERROR_CODES.CODE_503) {
          SystemException(result.code, data);
          return response;
        }

        if (isDormant || isLegacyApi || isNewAxios || isIgnorePath) {
          return response;
        }

        if (isErrorCodes) {
          ApiException(
            result.code,
            errorMessage(new Date(), result, {
              headers: response.config.headers,
              url: location.href.replace(location.origin, '')
            })
          );
        }

        return response;
      } catch (e) {
        return response;
      }
    },
    (error: AxiosError) => {
      if (!error.response) {
        NetworkException();
        return Promise.reject({ error: 'Error: Network Error', status: 9999 });
      } else {
        const { data, status } = error.response;
        ApiException(status, data);
        const res = { error: '', status: 0 };
        if (!data.data) {
          res.error = data;
          res.status = status;
        }
        return Promise.reject(res);
      }
    }
  );

  return axiosService;
};
